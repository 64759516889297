<template>
  <div class="md-layout-item md-size-100">
    <ItemsTable />
  </div>
</template>

<script>
export default {
  components: {
    ItemsTable: () => import("@/components/Tables/ItemsTable"),
  },
};
</script>
